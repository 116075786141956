import styled from "styled-components"

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  width: 100%;
  height: auto;
`

export const ChildWrapper = styled.div`
  grid-area: auto/2/auto/11;
  width: 100%;
  height: auto;
`
