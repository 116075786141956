import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import {
  ChildWrapper,
  MainWrapper,
} from "../components/StyledComponents/MainWrapper"
import { BlogDescriptionStyled } from "../components/StyledComponents/BlogDescriptionStyled"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw"
import { graphql } from "gatsby"
import SEo from "../components/Seo/seo"
import CustomAccordion from "../components/QuickyPedia/CustomAccordion"
import linkedin from "../images/linkedin.svg"
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share"
import {
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  TelegramShareButton,
  InstapaperShareButton,
} from "react-share"
const Wrapper = styled(MainWrapper)`
  margin-top: 100px;
  min-height: 700px;
  margin-bottom: 100px;
`

const Section = styled(ChildWrapper)`
  .heading {
    h1 {
      font-weight: 700;
      margin-bottom: 0.2rem;
    }
  }
  .share-buttons {
    display: flex;
    @media (max-width: 767px) {
      justify-content: flex-end;
      }
    svg {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 0.225rem;
      @media (max-width: 767px) {
        width: 20px;
        height: 20px;
      }
    }
  }
  .about_the_author {
    grid-area: 7/4/8/9;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    @media (max-width: 991px) {
      grid-area: 7/3/8/10;
    }
    @media (max-width: 767px) {
      grid-area: 7/2/8/11;
    }
    .heading_heading {
      h6 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;

        /* identical to box height */
        letter-spacing: 0.07em;
        @media (max-width: 550px) {
          font-size: 14px;
        }
      }
    }
    .author_wrapper {
      display: block;
      width: 55%;
      background: #f8f8f8;
      margin: 1rem 0;
      border-radius: 5px;
      padding: 20px;
      @media (max-width: 767px) {
        width: 100%;
      }
      .author_image {
        grid-area: auto/1/auto/2;
        display: block;
        width: 86.01px;
        height: 86.01px;
        border-radius: 92.3163px;
        @media (max-width: 479px) {
          width: 45px;
          height: 45px;
        }
        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
          border-radius: 92.3163px;
        }
      }
    }
    .author_desc_wrapper {
      grid-area: auto/2/auto/3;

      .name_and_links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .links {
          @media (max-width: 479px) {
            display: block;
          }
        }

        h3 {
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.07em;
          margin: 0.5rem;
          color: #000000;
          @media (max-width: 550px) {
            font-size: 16px;
          }
          @media (max-width: 479px) {
            margin-bottom: 0px;
            margin-top: 0px;
          }
        }

        a {
          text-decoration: none;
        }
        a:hover {
          img {
            transform: translateY(-2px) scale(1.1);
          }
        }
        img {
          width: 23.29px;
          height: 23.29px;
          margin-right: 10px;
          transition: 0.2s ease-in-out;
        }
      }
      .desc {
        grid-area: auto/2/auto/3;

        p {
          font-weight: 300;
          font-size: 17.8751px;
          line-height: 148.9%;

          /* or 27px */
          letter-spacing: 0.07em;

          color: #000000;

          @media (max-width: 550px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .list_of_topics {
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @media (max-width: 479px) {
      display: none;
    }
    .topics {
      margin-right: 1.5rem;
      @media (max-width: 479px) {
        display: none;
        flex: 0 0 fit-content;
        margin-right: 1.1rem;
      }
    }
    span {
      cursor: pointer;
      transition: 0.2s ease;
      font-weight: 500;
      text-transform: lowercase;
      border: none;
      outline: none;
      font-size: 1.2rem;
      &:hover {
        color: var(--purpleColor);
      }
    }
    .span_active {
      color: var(--purpleColor) !important;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  .description {
    pre, code {
      background: #f1f1f1;
      padding: 12px;
      font-size: 16px;
      font-family: inherit;
      font-weight: 500;
    }
    @media (max-width: 479px) {
      display: none;
    }
  }
  .mobile_version {
    display: none;
    @media (max-width: 479px) {
      display: block;
    }
  }
`
const BlogDescription = styled(BlogDescriptionStyled)`
  width: 80%;
  pre, code {
      background: #f1f1f1;
      padding: 12px;
      font-size: 16px;
      font-family: inherit;
      font-weight: 500;
    }
  @media (max-width: 767px) {
    width: 100%;
  }
`

function Where({ data, location }) {
  const {
    title,
    slug,
    title_and_description,
    seo: Seo,
    authorName,
    linkedInLink,
    authorDescription,
  } = data.strapiQuickyPedias
  const [topic, setTopic] = useState("what")
  const keys = []
  const keys1 =
    Seo === null
      ? { title }
      : Seo.keywords && Seo.keywords.map((k) => keys.push(k.title))
  const handleTopic = (e) => {
    setTopic(e.target.innerText)
  }

  return (
    <Wrapper>
      {Seo && (
        <SEo
          title={Seo.title && Seo.title}
          description={Seo.short_description && Seo.short_description}
          location={`${location.pathname}`}
          image={Seo.image  && Seo.image.url}
          keywords={keys}
        />
      )}
      <Section>
        <div className="heading">
          <h1>{title}</h1>
          <div className="share-buttons">
            <WhatsappShareButton
              title={`${Seo.title && Seo.title}`}
              separator=":: "
              url={location.href}
            >
              <WhatsappIcon />
            </WhatsappShareButton>
            <LinkedinShareButton
              title={`${Seo.title && Seo.title}`}
              source="Certisured"
              summary={Seo.short_description && Seo.short_description}
              url={`https://certisured.com${location.pathname}`}
            >
              <LinkedinIcon />
            </LinkedinShareButton>
            <FacebookShareButton
              url={`https://certisured.com${location.pathname}`}
              quote={title}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TelegramShareButton
              url={`https://certisured.com${location.pathname}`}
              title={title}
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>
        </div>
        <div className="list_of_topics">
          {title_and_description.map((l, i) => {
            return (
              <div className="topics" key={i}>
                <span
                  onClick={(e) => handleTopic(e)}
                  className={
                    topic.toLowerCase() === l.title.toLowerCase() &&
                    "span_active"
                  }
                  onKeyDown={(e) => handleTopic(e)}
                  role="button"
                  tabIndex={i}
                >
                  {l.title}
                </span>
              </div>
            )
          })}
        </div>
        <BlogDescription>
          <div className="description">
            {title_and_description.map(
              (l, i) =>
                l.title.toLowerCase() === topic.toLowerCase() && (
                  <ReactMarkdown
                    children={l.descrption}
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                  />
                )
            )}
          </div>
        </BlogDescription>
        <div className="mobile_version">
          <CustomAccordion data={title_and_description} />
        </div>
        <div className="about_the_author">
          <div className="heading_heading">
            {/* <h6>about the author</h6> */}
          </div>

          <div className="author_wrapper">
            {/* {  false &&   <div className="author_image">
            <GatsbyImage
              image={
                blog_author.image.localFile.childImageSharp.gatsbyImageData
              }
              alt=""
            />
          </div>} */}
            <div className="author_desc_wrapper">
              <div className="name_and_links">
                <h3>{authorName ? authorName : "name"}</h3>
                <div className="links">
                  <a href={linkedInLink ? linkedInLink : "/"}>
                    <img src={linkedin} alt="" />
                  </a>
                  {/* {false &&                <a href={blog_author.twitter_url}>
                  <img src={twitter} alt="" />
                </a>} */}
                </div>
              </div>
              <div className="desc">
                <p>{authorDescription ? authorDescription : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Wrapper>
  )
}

export default Where

export const query = graphql`
  query QuickyPedia($slug: String) {
    strapiQuickyPedias(slug: { eq: $slug }) {
      title
      slug
      authorName
      linkedInLink
      seo {
        short_description
        keywords {
          title
          id
        }
        title
        image {
          url
        }
      }
      title_and_description {
        descrption
        id
        title
      }
    }
  }
`
