import styled from "styled-components"

export const BlogDescriptionStyled = styled.div`
  grid-area: 5/4/6/9;
  margin-top: 0px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 479px) {
    margin-top: 20px;
    h1 {
      font-size: 24px;
      font-weight: 600;
    }
    h2 {
      font-size: 22px;
      font-weight: 500;
    }
    h3 {
      font-size: 20px;
      font-weight: 500;
    }
  }
  @media (max-width: 991px) {
    grid-area: 5/3/6/10;
  }

  @media (max-width: 767px) {
    grid-area: 5/2/6/11;
  }

  //table css

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    overflow-x: auto;

    th,
    td {
      text-align: left;
      padding: 8px;
    }
  }

  //end of table css
  h1 {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.07em;
    color: var(--secondaryColor);
  }
  h2 {
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.07em;
    color: var(--secondaryColor);
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.07em;
    color: var(--secondaryColor);
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.07em;
    color: var(--secondaryColor);
  }
  h5 {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.07em;
    color: var(--secondaryColor);
  }
  li {
    margin: 0.75rem 0;
    letter-spacing: 0.07em;
  }
  blockquote {
    border-left: 6px solid var(--purpleColor);
    margin: 0;
    padding-left: 1rem;
  }
  a {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.07em;
    @media (max-width: 550px) {
      font-size: 17px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.07em;
    margin: 2rem 0;
    @media (max-width: 550px) {
      font-size: 17px;
    }
    @media (max-width: 479px) {
      margin: 1rem 0;
    }
  }

  img {
    width: 100%;
    margin: 2rem 0;
  }
`
